body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  background-color: #fbf9ff;
}
html{
    height: 100%;
    width:100%
}
#root{
 height:100%;
 width:100%
}